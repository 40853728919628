import React, { useState } from "react";
import { CarouselItem } from "./CarouselItem";
export const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const items = [
    {
      title: "Nut And Bolts",
      icon: require("../component/Mlogo/cimg1.png"),
    },
    {
      title: "Washers",
      icon: require("../component/Mlogo/cimg2.png"),
    },
    {
      title: "Bolts",
      icon: require("../component/Mlogo/cimg3.png"),
    },
    {
      title: "Nuts",
      icon: require("../component/Mlogo/cimg4.png"),
    },
    {
      title: "Rounded Pipes",
      icon: require("../component/Mlogo/pr13-image4.png"),
    },
    {
      title: "J Type Foundations  bolts",
      icon: require("../component/Mlogo/pr4-image1.png"),
    },
    {
      title: "Bolts",
      icon: require("../component/Mlogo/cimg3.png"),
    },
    {
      title: "Washers",
      icon: require("../component/Mlogo/pr2-image2.png"),
    },
  ];
  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= items.length) {
      newIndex = items.length - 1;
    }

    setActiveIndex(newIndex);
  };
  return (
    <div className="carousel">
      <div
        className="inner"
        style={{ transform: `translate(-${100-activeIndex * 25}%)`
     }}
      >
        {items.map((item) => {
          return <CarouselItem item={item} width={"25%"} />;
        })}
      </div>

      <div className="carousel-buttons">
        <button
          className="button-arrow"
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}
        >
          <span class="material-symbols-outlined">{"<"}</span>{" "}
        </button>
        {/* <div className="indicators">
          {items.map((item, index) => {
            return (
              <button
                className="indicator-buttons"
                onClick={() => {
                  updateIndex(index);
                }}
              >
                <span
                  className={`material-symbols-outlined ${
                    index === activeIndex
                      ? "indicator-symbol-active"
                      : "indicator-symbol"
                  }`}
                >
                  
                </span>
              </button>
            );
          })}
        </div> */}
        <button
          className="button-arrow"
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
        >
          <span class="material-symbols-outlined">{">"}</span>
        </button>
      </div>
    </div>
  );
};